import React from "react"


export default props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48">
        <g fill="none">
            <rect x="5" y="10" width="38" height="28" rx="3" fill="#2F88FF" stroke="#000" strokeWidth="4"/>
            <path d="M13 24v-7" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="15" y="29" width="4" height="4" rx="2" transform="rotate(90 15 29)" fill="#fff"/>
        </g>
    </svg>
)