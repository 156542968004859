import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby'
import Helmet from 'react-helmet';
import Header from '../components/Header';
import ThikingIllustration from '../components/ThinkingIllustration';
import { StaticImage } from 'gatsby-plugin-image';
import CarrelageIcon from '../components/icons/CarrelageIcon';
import ConstructionIcon from '../components/icons/ConstructionIcon';
import GrosOeuvreIcon from '../components/icons/GrosOeuvreIcon';
import PeintureIcon from '../components/icons/PeintureIcon';
import RenovationIcon from '../components/icons/RenovationIcon';
import ProjetIllustration from '../components/ProjetIllustration';
import ResineIcon from '../components/icons/ResineIcon';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore, { Autoplay } from 'swiper';

SwiperCore.use([Autoplay]);

const Index = () => {
  const servicesData = [
    {icon: <ResineIcon width="21" height="25"/>, title: 'Résine'},
    {icon: <ConstructionIcon width="19" />, title: 'Construction'},
    {icon: <RenovationIcon width="19"/>, title: 'Rénovation'},
    {icon: <PeintureIcon width="19"/>, title: 'Peinture'},
    {icon: <GrosOeuvreIcon width="19"/>, title: 'Gros Oeuvre'},
    {icon: <CarrelageIcon width="19"/>, title: 'Carrelage'}
  ];

  const servicesImages = [
      <StaticImage alt="" src="../../static/images/resine.jpeg" placeholder="tracedSVG"/>,
      <StaticImage alt="" src="../../static/images/construction.png" placeholder="tracedSVG"/>,
      <StaticImage alt="" src="../../static/images/renovation.png" placeholder="tracedSVG"/>,
      <StaticImage alt="" src="../../static/images/peinture.png" placeholder="tracedSVG"/>,
      <StaticImage alt="" src="../../static/images/gros-oeuvre.png" placeholder="tracedSVG"/>,
      <StaticImage alt="" src="../../static/images/carrelage.png" placeholder="tracedSVG"/>
    ];
  const [serviceSelected, setServiceSelected] = useState(0);
  const [carouselActive, setCarouselActive] = useState(true);

  useEffect(() => {
    if(window.location.hash){
      document.querySelector(window.location.hash).scrollIntoView({behavior: 'auto'});

    }
  }, []);

  useEffect(() => {
    if(carouselActive){
      const slidesCount = servicesData.length;
      if(serviceSelected === slidesCount){
        setServiceSelected(0)
      }
      const id = setTimeout(()=> {
        setServiceSelected(serviceSelected + 1);
      }, 5000)
      return () => {
        clearTimeout(id);
      };
    }
  }, [serviceSelected]);

  const changeService = (index) => {
    setServiceSelected(index);
    setCarouselActive(false);
  };

  return (
    <div className="index">
      <Helmet title="EBNZ - Accueil" content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'/>
      <Header />
      <section className="index-hero">
        <div className="hero-flex">
          <h1 className="hero-text">
            Expérience, savoir-faire et compétence au service de vos <span className="bold-text">projets</span>.
          </h1>
          <ThikingIllustration className="thinking-ill"/>
        </div>
        <CTA />
      </section>
      <section id="nos-services" className="nos-services">
        <div className="content">
        {servicesImages[serviceSelected]}
          <div className="description">
            <h2 className="title">Nos services</h2>
            <div className="services-list">
              {servicesData.map((service,index) => 
                <div 
                  className={`service ${serviceSelected === index ? "active": ""}`}
                  onClick={() => changeService(index)}
                >
                  {service.icon}
                  <div>{service.title}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="qui-sommes-nous">
        <h2 className="title">Qui sommes nous</h2>
        <div className="content">
          <div className="description">
            EBNZ Maçonnerie Générale est une société crée en 2020 par Monsieur MIRANDA BORGES Amauri qu'a une large expérience dans le domaine de la construction et de la rénovation avec des projets au Portugal, Espagne, Royaume Uni, Belgique, Brésil et, bien sûr en France. Nous avons une expérience de 25 ans dans le bâtiment et nous sommes situés à Chartres(28). Nos fournissons des services en Île-de-France, Eure-et-Loir et Loiret. Nous sommes experts en résine (résidentielle, industrielle et parkings), gros œuvre, finition, peintures, rénovation, carrelage et construction d'escaliers. Avec une équipe ajustée à vos besoins, nous sommes là pour vous garantir le meilleur pour vos projets. N'hésitez pas à nous&nbsp;
            <Link to="/contact">contacter</Link>.
          </div>
          {/*Carousel should be place here below*/}
          <Swiper
            className='swiper-container'
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 3500,
              disableOnImage: true,
              stopOnHover: true,
              reverse: true,

            }}
          >
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-1.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-2.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-3.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-4.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-5.jpeg" placeholder="tracedSVG"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-6.jpeg" placeholder="tracedSVG" objectFit="cover"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-8.jpeg" placeholder="tracedSVG" objectFit="cover"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-9.jpeg" placeholder="tracedSVG" objectFit="cover"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-10.jpeg" placeholder="tracedSVG" objectFit="cover"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-11.jpeg" placeholder="tracedSVG" objectFit="cover"/>
            </SwiperSlide>
            <SwiperSlide>
              <StaticImage alt="" src="../../static/images/qsn/qsn-12.png" placeholder="tracedSVG" objectFit="cover"/>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <section className="projet">
        <h2 className="title">Quel que soit votre projet EBNZ est là pour vous aider</h2>
        <div className="content">
          <div className="description">
            EBNZ vous aide dans la réalisations de vos projets avec une équipe professionnelle, sérieuse et compétente.
            <ul>
              <li>
                Résine:
                <ul style={{
                    fontSize: 15,
                    marginTop: 0
                  }}
                >
                  <i>
                    <li style={{lineHeight: 1.2}}>Résidentielle</li>
                    <li style={{lineHeight: 1.2}}>Industrielle</li>
                    <li style={{lineHeight: 1.2}}>Parkings</li>
                  </i>
                </ul>
              </li>
              <li>Gros Œuvre</li>
              <li>Constructions</li>
              <li>Rénovation</li>
              <li>Peinture</li>
              <li>Carrelage</li>
              <li>Placo</li>
              <li>Petis services d’entretien de votre maison</li>
            </ul>
            <div className="slogan">Notre mission est de transformer votre projet en réalité.</div>
          </div>
          <ProjetIllustration className="illustration"/>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Index;
