import React from 'react'



export default props =>
    <svg {...props} viewBox="0 0 550 334" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.5595 170.838C52.5595 170.838 22.1227 244.966 51.0871 263.621C80.0515 282.275 132.087 275.402 116.378 306.331C100.669 337.257 144.851 334.804 184.616 331.857C224.379 328.912 546.418 310.748 546.418 310.748L443.818 255.766C443.818 255.766 371.163 225.329 402.089 189.002C433.018 152.674 435.963 1.96389 305.38 37.8009C174.797 73.6378 164.979 116.347 109.997 85.4191C55.0148 54.4908 -16.6592 41.7285 3.46884 84.9277C23.5951 128.129 62.377 148.257 52.5595 170.838Z" fill="#F7FAFD"/>
        <path d="M166.961 333.09C168.002 333.016 169.05 332.935 170.1 332.846V271.866H166.961V333.09Z" fill="#9A9391"/>
        <path d="M170.1 332.847C171.589 332.76 173.082 332.664 174.578 332.567V272.927H170.1V332.847Z" fill="#9A9391"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M170.1 333.005C171.587 332.926 173.081 332.839 174.578 332.747V272.927H170.1V333.005Z" fill="#9A9391"/>
            </g>
        </g>
        <path d="M180.701 221.625L113.412 320.305C114.073 323.329 115.869 325.708 118.565 327.563L187.596 226.327L180.701 221.625Z" fill="#677786"/>
        <path d="M180.701 221.625L180.154 222.424L185.878 226.327L117.434 326.7C117.792 326.999 118.165 327.289 118.565 327.563L187.596 226.327L180.701 221.625Z" fill="#677786"/>
        <path d="M376.072 243.493H35.0499V5.23569H376.072V243.493Z" fill="#F2F7FD"/>
        <path d="M379.344 249.385H31.1222V240.221H379.344V249.385Z" fill="#677786"/>
        <path d="M160.806 278.021V267.712L165.623 279.391L160.806 278.021Z" fill="#9A9391"/>
        <path d="M366.336 124.835H308.108V118.799H366.336V124.835Z" stroke="#9A9391" strokeWidth="0.185333" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M334.381 128.74H329.41V114.893H334.381V128.74Z" stroke="#9A9391" strokeWidth="0.185333" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M31.1231 249.711H31.1284H31.9875H379.345V248.92H31.9875V240.548H31.1284V248.92H31.1231V249.711Z" fill="#677786"/>
        <path d="M549.61 300.439V310.748L530.894 301.452L549.61 300.439Z" fill="#9A9391"/>
        <path d="M549.61 310.748L474.01 269.511L386.627 271.148L160.807 278.021L182.646 331.152L549.61 310.748Z" fill="#9A9391"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M532.415 301.37L474.009 269.512L386.626 271.148L165.005 277.894L160.806 267.712V278.021L182.645 331.152L549.61 310.749V300.44L532.415 301.37Z" fill="#9A9391"/>
            </g>
        </g>
        <path d="M549.61 300.439L474.01 259.202L386.627 260.839L160.807 267.712L182.646 320.843L549.61 300.439Z" fill="#9A9391"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M505.059 283.503L502.89 281.212L469.836 292.747L517.7 286.326L505.059 283.503Z" fill="#9A9391"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M238.084 311.894L394.072 299.457C394.072 299.457 325.508 293.076 324.854 293.076C324.198 293.076 247.615 291.93 246.961 291.93C246.307 291.93 238.084 311.894 238.084 311.894Z" fill="#9A9391"/>
            </g>
        </g>
        <path d="M267.416 284.771C267.416 284.771 247.452 285.773 243.341 282.337C239.229 278.901 244.691 279.146 239.291 278.471C233.891 277.796 194.107 279.862 194.68 257.279C195.253 234.698 230.761 155.006 263.326 141.096C295.889 127.188 313.235 127.392 313.235 127.392C313.235 127.392 367.726 130.46 378.035 155.251C388.345 180.042 384.909 259.57 369.444 265.461C353.981 271.353 341.217 218.089 341.217 218.089C341.217 218.089 334.508 239.566 340.235 262.762L285.989 264.235C285.989 264.235 286.807 266.648 286.234 271.475C286.234 271.475 287.79 274.135 286.644 275.281C285.497 276.426 284.638 277.734 282.307 276.875C282.307 276.875 283.719 280.066 282.185 282.153C280.65 284.239 276.968 282.583 276.968 282.583C276.968 282.583 276.785 283.442 275.066 284.055C273.348 284.669 270.709 285.344 268.807 284.055C268.807 284.055 268.111 284.812 267.416 284.771Z" fill="#2D78A7"/>
        <path d="M514.182 282.112L359.831 262.475L219.102 267.057L176.393 270.166L187.683 277.53L238.083 311.895C238.083 311.895 254.704 299.947 268.847 301.748C284.272 303.712 461.613 300.112 493.686 287.676L514.182 282.112Z" fill="white"/>
        <g opacity="0.100006">
            <g opacity="0.100006">
                <path opacity="0.100006" d="M467.75 279.085C467.75 279.085 377.422 296.02 365.026 294.18C352.631 292.34 297.649 280.803 307.958 279.944C318.267 279.085 467.75 279.085 467.75 279.085Z" fill="#423E3E"/>
            </g>
        </g>
        <g opacity="0.100006">
            <g opacity="0.100006">
                <path opacity="0.100006" d="M281.019 283.114C281.019 283.114 279.525 284.484 278.952 284.484C278.379 284.484 277.48 283.911 277.48 283.911C277.48 283.911 272.325 286.529 270.362 285.957C268.398 285.384 262.671 285.384 258.662 285.384C254.653 285.384 240.416 285.14 240.907 280.802C241.397 276.466 215.134 279.084 207.853 277.203C201.138 275.467 194.025 270.493 197.461 269.594C200.897 268.694 201.961 269.021 204.006 269.267C206.052 269.512 235.834 276.139 237.552 276.139C239.271 276.139 251.543 277.857 252.689 278.43C253.834 279.003 281.019 283.114 281.019 283.114Z" fill="#423E3E"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M355.093 136.363C352.907 139.15 358.234 143.838 358.234 143.838C358.234 143.838 373.945 150.383 377.381 168.548C379.404 179.238 381.876 190.74 383.906 204.222C384.158 184.599 382.242 165.368 378.035 155.251C374.414 146.544 365.34 140.518 355.093 136.363Z" fill="#CD7B3D"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M265.944 197.184C265.944 197.184 240.742 244.966 233.87 249.384C233.87 249.384 234.526 258.547 235.015 258.547C235.507 258.547 258.436 261.105 258.436 261.105C258.436 261.105 257.926 211.42 265.944 197.184Z" fill="#2D78A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M263.325 141.097C234.645 153.346 203.687 216.614 196.316 246.962C199.609 245.292 203.586 242.997 207.198 241.161C207.198 241.161 201.674 241.161 203.761 233.92C205.847 226.68 222.17 195.261 222.17 195.261C222.17 195.261 258.866 135.124 308.326 130.583L315.812 128.743C315.812 128.743 314.869 128.17 313.528 127.41C313.34 127.398 313.234 127.392 313.234 127.392C313.234 127.392 295.889 127.187 263.325 141.097" fill="#2D78A7"/>
            </g>
        </g>
        <path d="M282.185 276.998L287.912 283.871C287.912 283.871 284.557 275.034 284.802 275.034C285.048 275.034 282.185 276.998 282.185 276.998Z" fill="#E6685A"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M282.185 276.998L287.912 283.871C287.912 283.871 284.557 275.034 284.802 275.034C285.048 275.034 282.185 276.998 282.185 276.998Z" fill="#E6685A"/>
            </g>
        </g>
        <path d="M279.28 262.148C276.416 262.093 274.944 261.003 273.799 260.184C272.653 259.366 269.42 256.994 264.757 254.908C260.094 252.82 254.94 257.853 253.589 259.203C252.239 260.552 242.911 254.416 242.911 254.416C242.911 254.416 229.533 246.315 217.015 242.389C209.354 239.985 201.608 242.821 196.746 245.384C195.48 250.232 194.757 254.344 194.679 257.402C194.107 279.985 233.891 277.919 239.291 278.594C244.691 279.269 239.229 279.024 243.34 282.46C247.452 285.896 267.416 284.894 267.416 284.894C268.112 284.934 268.807 284.178 268.807 284.178C270.709 285.467 273.348 284.791 275.066 284.178C276.784 283.565 276.968 282.706 276.968 282.706C276.968 282.706 280.65 284.362 282.184 282.276C283.718 280.188 282.308 276.998 282.308 276.998C284.639 277.857 285.498 276.549 286.644 275.403C287.789 274.258 286.234 271.598 286.234 271.598C286.807 266.77 285.99 264.357 285.99 264.357C285.99 264.357 285.652 262.27 279.28 262.148" fill="#BA947E"/>
        <path d="M309.717 129.765C309.717 129.765 297.158 147.438 315.689 181.802C315.689 181.802 315.873 183.234 315.076 185.198C314.279 187.162 315.138 187.835 316.181 188.88C317.224 189.923 317.04 193.175 317.04 193.175L328.637 178.815L348.825 155.21L332.544 152.346L309.717 129.765Z" fill="#C29A83"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M309.717 129.765C309.717 129.765 297.158 147.438 315.689 181.802C315.689 181.802 315.873 183.234 315.076 185.198C314.279 187.162 315.138 187.835 316.181 188.88C317.224 189.923 317.04 193.175 317.04 193.175L328.637 178.815L348.825 155.21L332.544 152.346L309.717 129.765Z" fill="#BA947E"/>
            </g>
        </g>
        <path d="M309.717 129.765C309.717 129.765 303.396 135.451 301.453 139.052C297.28 146.784 308.163 189.82 308.163 189.82L315.689 181.802C315.689 181.802 301.453 144.82 309.717 129.765Z" fill="#23628A"/>
        <path d="M252.362 232.836L268.254 256.687C268.254 256.687 273.471 259.918 277.48 263.846L256.044 231.2C256.044 231.2 254.734 230.219 252.362 232.836Z" fill="#E6685A"/>
        <path d="M314.585 103.337C314.585 103.337 314.053 110.62 313.48 108.083C312.907 105.547 310.944 101.455 309.471 105.137C307.999 108.819 305.626 121.91 309.39 124.857C309.39 124.857 309.962 127.474 309.717 129.765C309.471 132.056 304.644 156.847 318.472 163.147C332.298 169.447 353.98 151.201 356.271 148.011C358.562 144.82 358.562 140.974 360.036 140.155C361.508 139.337 370.099 130.419 369.526 128.128C368.954 125.838 367.481 124.938 365.19 126.983C362.9 129.028 367.154 122.728 367.154 122.728L314.585 103.337Z" fill="#BA947E"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M313.48 108.083C312.908 105.546 310.944 101.456 309.471 105.138C307.999 108.82 305.625 121.911 309.39 124.856C309.39 124.856 309.963 127.474 309.717 129.765C309.606 130.793 308.575 136.367 308.722 142.762C310.562 144.008 312.547 143.835 314.094 141.997C318.021 137.334 314.094 122.114 318.021 116.96C321.949 111.806 359.135 124.324 359.135 124.324L353.549 117.709L314.585 103.337C314.585 103.337 314.053 110.619 313.48 108.083Z" fill="#BA947E"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M325.18 182.784L322.236 186.22C322.236 186.22 331.89 191.784 332.544 195.875C333.198 199.965 337.207 205.284 335.081 213.302C333.068 220.89 332.742 253.954 337.579 262.834L340.235 262.762C334.508 239.565 341.216 218.088 341.216 218.088C341.216 218.088 345.482 235.883 352.066 249.764C355.435 238.065 360.035 221.965 360.035 221.238C360.035 220.093 336.635 191.456 336.635 191.456L325.18 182.784Z" fill="#2D78A7"/>
            </g>
        </g>
        <path d="M367.874 131.77C366.937 132.336 366.591 132.21 365.537 133.672C364.362 135.304 363.86 134.756 364.167 133.467C364.473 132.178 362.357 134.634 362.357 134.634C360.001 137.953 359.75 139.42 360.289 139.981C361.624 139.009 365.583 134.98 367.874 131.77Z" fill="#BA947E"/>
        <path d="M365.19 126.983C363.374 128.604 365.662 124.996 366.694 123.421L366.818 122.605L365.692 122.189C365.307 122.826 364.865 123.443 364.371 123.955C362.408 126.002 361.262 133.693 359.544 139.174C357.826 144.656 350.626 148.584 347.517 150.302C344.408 152.02 342.935 152.02 341.463 149.402C339.989 146.784 339.275 146.374 331.828 142.856C324.382 139.337 316.589 141.138 314.544 141.711C312.498 142.284 310.535 126.819 310.535 123.711C310.535 120.602 311.434 115.364 313.971 113.074C315.892 111.34 318.185 107.028 319.187 105.034L314.584 103.338C314.584 103.338 314.052 110.62 313.48 108.084C313.476 108.066 313.471 108.048 313.467 108.032C311.82 115.029 309.389 124.857 309.389 124.857C309.389 124.857 309.962 127.475 309.716 129.765C309.471 132.056 304.643 156.847 318.471 163.147C332.298 169.447 353.981 151.201 356.272 148.011C358.563 144.82 358.563 140.974 360.035 140.156C360.157 140.088 360.343 139.947 360.553 139.777C361.237 136.652 363.647 130.693 365.227 126.951C365.215 126.964 365.202 126.971 365.19 126.983Z" fill="#373231"/>
        <path d="M319.208 144.329C319.208 144.329 327.409 145.31 328.698 145.985C329.987 146.66 330.54 147.152 332.196 147.335C333.852 147.519 336.246 149.115 337.964 150.342C339.682 151.569 330.478 153.473 326.612 151.139C322.746 148.808 316.098 144.574 319.208 144.329Z" fill="#F7C4A7"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M344.715 155.252C343.426 156.326 340.603 159.946 339.652 159.425C338.701 158.904 328.668 154.976 328.544 154.76C328.428 154.555 311.338 151.773 309.825 151.527C311.02 156.206 313.366 160.42 317.672 162.732C320.042 163.415 322.504 164.104 322.93 164.15C323.245 164.183 324.74 164.279 326.536 164.385C335.832 163.65 346.148 156.873 351.916 152.139L351.987 152.029C351.987 152.029 351.967 152.036 351.93 152.05C351.319 152.271 345.929 154.239 344.715 155.252Z" fill="#373231"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M324.526 150.629C320.395 145.637 317.653 148.338 313.807 141.301C313.786 141.262 313.772 141.213 313.75 141.172C312.02 138.45 310.535 126.414 310.535 123.709C310.535 120.602 311.435 115.364 313.971 113.074C315.893 111.34 318.185 107.027 319.188 105.034L314.585 103.338C314.585 103.338 314.053 110.618 313.48 108.084C313.476 108.066 313.471 108.048 313.468 108.032C311.82 115.029 309.39 124.855 309.39 124.855C309.39 124.855 309.962 127.475 309.717 129.765C309.556 131.273 307.41 142.535 309.908 151.85C313.51 151.714 318.735 151.079 319.248 151.079C319.985 151.079 328.659 155.621 324.526 150.629" fill="#373231"/>
            </g>
        </g>
        <path d="M319.208 144.329C319.208 144.329 327.409 145.31 328.698 145.985C329.987 146.66 330.54 147.152 332.196 147.335C333.852 147.519 336.246 149.115 337.964 150.342C339.682 151.569 330.478 153.473 326.612 151.139C322.746 148.808 316.098 144.574 319.208 144.329Z" fill="#BA947E"/>
        <path d="M306.607 147.929C306.607 147.929 314.544 145.719 318.553 147.11C322.562 148.501 331.398 153.164 336.88 157.992C342.363 162.819 342.117 158.075 345.227 156.028C348.336 153.983 352.804 151.033 351.986 152.505C351.168 153.977 347.517 161.428 347.517 164.864C347.517 168.302 346.289 175.748 351.116 180.902C355.944 186.056 382.085 223.12 386.872 242.02C391.659 260.921 378.771 266.934 367.971 266.075C357.172 265.216 343.18 253.189 339.254 212.444C339.254 212.444 338.271 197.961 333.854 194.401C329.435 190.843 320.598 181.883 318.389 180.165C316.181 178.447 311.824 176.053 309.676 175.624C307.528 175.194 305.564 174.519 305.564 171.758C305.564 168.997 308.142 168.69 309.86 168.935C309.86 168.935 309.246 165.561 310.289 163.843C311.332 162.125 312.007 161.511 312.867 161.695C312.867 161.695 313.542 157.645 316.303 157.829C319.064 158.013 324.464 159.363 322.746 157.829C321.028 156.295 315.136 151.262 309.736 152.121C304.338 152.981 305.257 148.215 306.607 147.929Z" fill="#BA947E"/>
        <path d="M319.858 115.676C319.858 115.676 320.794 115.503 322.217 115.508C323.642 115.483 325.556 115.681 327.432 116.203C328.363 116.464 329.33 116.72 330.15 117.162C330.562 117.376 330.937 117.627 331.257 117.915C331.591 118.182 331.892 118.449 332.13 118.744C332.634 119.296 332.977 119.817 333.152 120.234C333.353 120.623 333.435 120.862 333.435 120.862C333.454 120.919 333.424 120.979 333.368 120.998C333.345 121.005 333.32 121.005 333.299 120.998L333.29 120.995C333.29 120.995 333.041 120.908 332.664 120.724C332.468 120.639 332.245 120.526 332.003 120.392C331.747 120.27 331.455 120.144 331.163 119.983C330.566 119.679 329.887 119.331 329.166 118.955C328.476 118.522 327.718 118.053 326.905 117.684C325.281 116.924 323.499 116.491 322.152 116.204C320.796 115.936 319.866 115.872 319.866 115.872C319.812 115.868 319.771 115.821 319.774 115.766C319.778 115.72 319.815 115.685 319.858 115.676Z" fill="#373231"/>
        <path d="M356.412 129.645C356.412 129.645 355.745 129.129 354.658 128.532C354.4 128.366 354.078 128.245 353.764 128.09C353.437 127.954 353.129 127.749 352.761 127.628C352.035 127.377 351.305 127.029 350.504 126.884C349.723 126.681 348.935 126.557 348.192 126.485C347.83 126.412 347.482 126.327 347.155 126.207C346.829 126.078 346.508 126.041 346.214 125.955C345.037 125.65 344.187 125.175 344.187 125.175L344.176 125.168C344.125 125.14 344.105 125.074 344.135 125.023C344.146 125.004 344.162 124.988 344.181 124.979C344.181 124.979 345.009 124.578 346.347 124.388C346.679 124.344 347.047 124.272 347.423 124.327C347.803 124.346 348.203 124.406 348.611 124.484C349.012 124.601 349.44 124.662 349.836 124.857C350.232 125.039 350.635 125.223 351.038 125.407C351.86 125.718 352.59 126.227 353.295 126.623C353.649 126.822 353.953 127.082 354.252 127.301C354.541 127.537 354.833 127.729 355.063 127.963C356.04 128.811 356.549 129.506 356.549 129.506C356.583 129.548 356.576 129.612 356.534 129.645C356.498 129.674 356.447 129.67 356.412 129.645Z" fill="#373231"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M387.564 253.285C386.29 249.676 382.86 247.514 375.827 249.017C355.699 253.312 350.79 213.915 350.79 209.989C350.79 206.061 348.581 197.593 348.213 201.029C347.844 204.465 345.144 198.452 340.48 193.789C337.676 190.983 332.971 190.446 329.391 190.462C331.06 192.009 332.628 193.416 333.853 194.402C338.272 197.96 339.253 212.442 339.253 212.442C343.181 253.188 357.171 265.217 367.972 266.076C376.232 266.732 385.705 263.361 387.564 253.285Z" fill="#BA947E"/>
            </g>
        </g>
        <path d="M310.859 93.7298C310.859 93.7298 306.203 107.024 342.278 119.108C378.352 131.191 375.273 117.071 375.308 114.77C375.344 112.47 381.723 79.1344 357.291 69.0024C357.291 69.0024 356.626 68.891 356.241 67.6908C355.856 66.4905 356.027 65.9019 354.733 65.1843C353.441 64.4648 352.909 65.0817 352.25 65.955C351.592 66.8282 350.39 66.7999 347.636 65.9921C344.882 65.1843 323.81 61.6808 310.859 93.7298Z" fill="#FCCD40"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M346.822 86.4927C347.127 85.0503 351.86 70.4656 353.488 64.9011C352.995 65.0106 352.643 65.4331 352.251 65.9546C351.696 66.6881 350.747 66.7783 348.823 66.3099C348.38 71.7471 345.995 85.9659 344.832 87.0601C343.545 88.2709 337.116 89.7221 336.941 90.7226C336.766 91.7213 332.48 108.311 332.48 108.311C332.48 108.311 337.245 91.3537 337.726 90.9878C338.205 90.6219 346.453 88.2462 346.822 86.4927Z" fill="#FCCD40"/>
            </g>
        </g>
        <path d="M306.822 277.888L368.309 270.77L365.608 290.406C365.608 290.406 312.714 283.288 306.822 277.888Z" fill="#F2F7FD"/>
        <path d="M306.822 277.888L368.309 270.77L365.608 290.406C365.608 290.406 312.714 283.288 306.822 277.888Z" fill="#F2F7FD"/>
        <path d="M367.726 277.612L344.531 281.048L366.499 284.73L367.726 277.612Z" fill="#423E3E"/>
        <path d="M306.822 277.888C306.822 277.888 361.713 290.498 365.64 290.007L368.79 293.486C368.79 293.486 361.918 293.076 358.236 292.176C354.554 291.276 306.822 281.856 306.822 277.888Z" fill="#F2F7FD"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M306.822 277.888C306.822 277.888 361.713 290.498 365.64 290.007L368.504 293.362C368.504 293.362 361.918 293.076 358.236 292.176C354.554 291.276 306.822 281.856 306.822 277.888Z" fill="#F2F7FD"/>
            </g>
        </g>
        <path d="M472.414 188.879L386.627 191.456C386.627 191.456 377.299 190.965 376.318 200.416C375.335 209.866 365.64 290.007 365.64 290.007C365.64 290.007 364.781 294.426 372.512 293.443C380.244 292.462 466.646 279.453 466.646 279.453C466.646 279.453 470.574 279.575 471.433 268.776C472.292 257.976 481.25 193.053 481.25 193.053C481.25 193.053 482.355 188.757 477.322 188.757C471.677 188.757 472.414 188.879 472.414 188.879Z" fill="#F2F7FD"/>
        <path d="M366.785 290.007C366.785 290.007 376.481 209.867 377.462 200.415C378.445 190.965 387.772 191.457 387.772 191.457L473.559 188.879C473.559 188.879 472.933 188.773 477.358 188.759C477.345 188.759 477.335 188.756 477.322 188.756C471.677 188.756 472.414 188.879 472.414 188.879L386.627 191.457C386.627 191.457 377.299 190.965 376.316 200.415C375.335 209.867 365.64 290.007 365.64 290.007C365.64 290.007 364.905 293.827 371.031 293.567C366.147 293.331 366.785 290.007 366.785 290.007Z" fill="#F2F7FD"/>
        <path d="M348.797 131.742C348.541 132.616 347.871 133.186 347.302 133.019C346.731 132.851 346.478 132.007 346.735 131.136C346.991 130.263 347.661 129.692 348.23 129.86C348.799 130.026 349.054 130.869 348.797 131.742Z" fill="#373231"/>
        <path d="M326.656 124.033C326.396 124.918 325.716 125.498 325.138 125.326C324.56 125.157 324.304 124.303 324.563 123.417C324.823 122.532 325.504 121.952 326.082 122.122C326.658 122.293 326.916 123.147 326.656 124.033Z" fill="#373231"/>
        <path d="M325.307 123.868C325.277 124.079 325.141 124.234 325.003 124.215C324.865 124.195 324.778 124.008 324.808 123.798C324.838 123.587 324.975 123.432 325.112 123.451C325.249 123.471 325.337 123.658 325.307 123.868Z" fill="white"/>
        <path d="M347.429 131.692C347.399 131.903 347.263 132.058 347.125 132.039C346.987 132.019 346.9 131.832 346.93 131.622C346.96 131.411 347.096 131.256 347.234 131.275C347.37 131.295 347.459 131.482 347.429 131.692Z" fill="white"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M276.6 262.516C276.6 262.516 281.08 263.559 280.466 264.664C279.853 265.769 279.331 266.106 279.331 266.106L276.539 262.67" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M283.044 277.366C283.044 277.366 280.753 275.648 278.176 272.947C275.598 270.248 275.639 270.821 272.897 269.675C270.158 268.53 262.589 263.049 261.729 262.066C260.87 261.085 261.729 262.476 262.426 263.335C263.121 264.194 266.557 267.058 268.561 267.917C270.566 268.776 273.47 270.043 275.598 271.926C277.725 273.808 282.922 278.104 283.044 277.531C283.166 276.958 283.044 277.366 283.044 277.366Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M276.969 282.705C276.969 282.705 277.299 282.852 277.798 283.004C276.997 282.601 276.058 282.044 275.026 281.252C272.039 278.961 268.48 277.407 267.457 275.976C266.433 274.544 258.702 271.108 259.847 272.089C260.993 273.071 265.29 276.916 266.722 277.08C268.153 277.243 274.699 281.416 273.962 282.808C273.225 284.199 271.793 284.975 269.502 284.239C267.211 283.502 258.499 277.448 258.088 278.594C257.68 279.739 258.988 279.002 259.971 279.576C260.952 280.149 267.416 284.034 265.984 284.034C264.553 284.034 251.502 282.808 250.93 280.803C250.357 278.799 251.175 277.694 251.338 276.67C251.502 275.649 250.603 273.48 251.338 272.499C252.075 271.516 251.748 268.96 252.975 268.96C254.203 268.96 251.87 264.725 253.588 263.866C255.306 263.007 256.595 260.613 256.595 260.613C256.595 260.613 249.906 262.577 247.575 265.34C245.243 268.101 238.8 269.511 232.357 269.941C225.914 270.371 195.907 264.603 199.465 248.77C199.465 248.77 201.982 243.31 206.277 242.021C206.277 242.021 204.962 242.203 203.039 242.753C200.656 243.492 198.509 244.453 196.745 245.383C195.481 250.232 194.756 254.343 194.68 257.403C194.107 279.985 233.891 277.918 239.291 278.594C244.692 279.269 239.23 279.023 243.341 282.459C247.453 285.896 267.416 284.893 267.416 284.893C268.111 284.934 268.807 284.177 268.807 284.177C270.709 285.466 273.348 284.791 275.067 284.177C276.785 283.564 276.969 282.705 276.969 282.705Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M315.567 168.997C312.417 167.268 310.527 167.659 309.737 167.956C309.788 168.539 309.859 168.935 309.859 168.935C309.68 168.911 309.49 168.893 309.293 168.88C312.632 169.434 317.301 170.929 318.308 171.288C319.454 171.696 319.74 171.288 315.567 168.997Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M322.194 164.293C321.842 163.844 315.686 161.887 312.979 161.201C312.902 161.498 312.866 161.694 312.866 161.694C312.652 161.648 312.449 161.653 312.249 161.712C313.472 161.901 319.279 162.889 321.581 164.825C324.158 166.992 322.645 164.865 322.194 164.293Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M327.349 157.706C326.203 158.157 326.203 158.114 322.889 156.234C320.997 155.159 318.576 154.247 316.844 153.66C319.562 155.06 321.795 156.98 322.746 157.828C323.29 158.314 323.119 158.51 322.548 158.546C323.884 158.721 325.473 158.712 326.49 158.525C328.043 158.238 328.494 157.255 327.349 157.706Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M365.19 130.338C365.19 130.338 368.707 126.942 368.462 128.62C368.218 130.297 364.782 134.592 364.782 134.592C364.782 134.592 367.726 129.479 366.171 130.051C364.617 130.624 365.19 130.338 365.19 130.338Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M311.394 109.228C311.394 109.228 310.616 104.36 309.921 106.365C309.225 108.369 308.939 112.502 308.939 112.502C308.939 112.502 309.267 109.228 311.394 109.228Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M311.68 113.156C311.68 113.156 309.921 111.846 309.225 114.137C308.53 116.428 308.08 121.133 310.084 121.011C310.084 121.011 308.53 115.733 311.68 113.156Z" fill="#F7C4A7"/>
            </g>
        </g>
        <path d="M242.911 254.416C242.911 254.416 229.534 246.317 217.016 242.389C212.929 241.108 208.824 241.322 205.139 242.184C204.526 242.759 204.089 243.165 204.089 243.165C204.089 243.165 217.016 241.776 228.716 248.321C238.058 253.546 247.288 259.183 251.925 259.206C248.838 258.312 242.911 254.416 242.911 254.416Z" fill="#BA947E"/>
        <path d="M286.112 272.662C286.432 273.111 286.739 273.459 287.022 273.74C286.858 272.665 286.234 271.598 286.234 271.598C286.807 266.77 285.99 264.357 285.99 264.357C285.99 264.357 285.649 262.328 279.511 262.157C280.098 262.351 280.768 262.599 281.489 262.926C283.657 263.907 282.798 264.603 283.084 265.462C283.37 266.321 284.353 267.629 284.926 268.202C285.498 268.775 285.907 272.008 285.498 271.189C285.088 270.371 279.485 266.608 278.502 265.052C278.062 264.354 276.703 263.131 274.985 261.556C274.373 261.23 273.919 260.668 273.471 260.348C272.326 259.53 269.42 256.993 264.757 254.908C263.587 254.384 262.387 254.308 261.224 254.51C260.163 255.503 259.382 256.88 260.154 258.466C260.154 258.466 261.383 255.643 264.206 256.072C267.027 256.502 278.013 264.787 278.626 266.014C279.239 267.24 285.007 271.108 286.112 272.662Z" fill="#BA947E"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M226.711 246.132C226.711 246.132 232.479 248.709 238.493 240.978C244.506 233.246 266.966 194.035 266.966 194.035C266.966 194.035 245.673 238.277 233.87 249.384L226.711 246.132Z" fill="#2D78A7"/>
            </g>
        </g>
        <path d="M347.517 164.865C347.517 161.457 351.102 154.113 351.961 152.552C351.934 152.391 351.911 152.246 351.888 152.115C351.065 152.183 347.713 154.392 345.226 156.029C342.117 158.074 342.363 162.82 336.881 157.993C335.851 157.086 334.702 156.184 333.491 155.309C331.433 155.458 329.475 156.123 329.128 157.339C328.515 159.486 325.508 160.713 326.367 163.106C327.226 165.5 323.237 168.077 323.85 169.366C324.465 170.654 322.991 171.759 324.036 174.95C325.079 178.14 326.122 183.478 330.663 185.013C335.204 186.547 345.021 186.363 346.493 189.799C347.968 193.236 348.028 194.279 349.93 191.088C351.833 187.898 351.833 186.731 354.411 189.124C356.988 191.518 373.148 217.353 371.866 210.921C363.267 196.764 353.829 183.797 351.118 180.901C346.29 175.747 347.517 168.302 347.517 164.865Z" fill="#BA947E"/>
        <path d="M338.517 126.042C338.517 126.042 334.038 136.413 334.897 138.069C335.756 139.727 334.959 140.157 335.531 140.157C336.104 140.157 335.121 138.969 335.245 138.152C335.367 137.334 334.916 137.047 335.367 136.188C335.818 135.329 339.498 125.388 338.517 126.042Z" fill="#C29A83"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M329.822 138.993C330.087 139.378 330.196 139.764 330.065 139.854C329.935 139.944 329.615 139.703 329.35 139.318C329.084 138.931 328.975 138.546 329.106 138.457C329.236 138.367 329.556 138.608 329.822 138.993Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M336.411 141.45C336.872 141.372 337.268 141.436 337.294 141.592C337.321 141.747 336.967 141.936 336.506 142.014C336.045 142.094 335.649 142.03 335.622 141.874C335.596 141.717 335.949 141.528 336.411 141.45Z" fill="#F7C4A7"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M318.573 144.421C318.573 144.421 323.944 145.893 326.613 147.306C329.282 148.716 330.017 148.624 331.643 148.932C333.27 149.238 338.302 150.65 338.302 150.65C338.302 150.65 334.774 149.146 333.915 148.716C333.056 148.287 330.938 148.716 330.171 148.073C329.404 147.428 327.686 146.69 325.538 146.261C323.39 145.831 317.744 143.684 318.573 144.421Z" fill="#BA947E"/>
            </g>
        </g>
        <g opacity="0.300003">
            <g opacity="0.300003">
                <path opacity="0.300003" d="M364.044 127.76C364.281 127.334 363.899 125.655 363.71 124.906C362.132 127.789 361.061 134.338 359.544 139.174C357.826 144.655 350.626 148.583 347.517 150.301C344.408 152.019 342.935 152.019 341.463 149.401C340.072 146.929 339.349 146.423 333.003 143.413C332.649 143.604 332.443 143.715 332.443 143.715C332.443 143.715 337.473 145.771 338.64 148.134C339.805 150.496 342.322 153.289 347.015 151.263C350.478 149.769 355.153 147.211 357.493 145.834C358.63 143.264 358.888 140.793 360.035 140.157C360.157 140.088 360.343 139.948 360.553 139.777C361.126 137.166 362.901 132.579 364.391 128.957C364.168 128.349 363.846 128.116 364.044 127.76Z" fill="#373231"/>
            </g>
        </g>
        <path d="M338.114 150.495C336.135 149.982 330.599 148.579 329.619 148.655C328.423 148.747 328.299 148.347 327.348 147.918C326.397 147.488 325.508 149.698 325.937 150.035C326.178 150.225 326.563 150.903 327.586 151.6C331.638 153.113 338.873 151.619 338.114 150.495Z" fill="#BA947E"/>
        <path d="M338.118 150.501C338.083 150.448 338.037 150.394 337.964 150.342C336.246 149.116 333.853 147.519 332.197 147.336C330.601 147.159 330.022 146.694 328.831 146.058C328.944 146.546 329.238 147.169 330.028 147.417C331.399 147.846 332.81 148.216 333.321 148.419C333.727 148.582 336.646 149.968 338.118 150.501Z" fill="#BA947E"/>
        <path d="M308.776 149.197C308.776 148.642 308.869 147.854 308.967 147.378C307.565 147.664 306.607 147.93 306.607 147.93C306.149 148.025 305.748 148.642 305.619 149.376C305.933 150.434 308.776 149.789 308.776 149.197Z" fill="#FEF2F9"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M350.562 80.4516C350.562 80.4516 347.619 87.8244 349.041 89.7228C350.464 91.6213 354.336 96.8959 354.336 96.8959C354.336 96.8959 349.977 90.0605 349.899 89.0812C349.823 88.1019 351.87 76.7785 350.562 80.4516Z" fill="#FCCD40"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M321.647 75.8008C318.186 79.5658 314.806 84.6672 311.805 91.5133C311.748 93.0935 311.906 95.8139 313.295 98.3381C315.418 102.192 316.802 104.053 316.802 104.053C316.802 104.053 313.258 96.9452 313.801 94.6049C314.196 92.8938 317.477 82.9297 321.647 75.8008Z" fill="#FCCD40"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M277.48 263.846L276.93 263.01C276.177 262.327 274.89 261.173 273.593 260.061C271.588 258.343 268.725 256.749 268.152 255.48C267.645 254.357 255.556 236.455 252.802 232.38C252.659 232.518 252.512 232.67 252.362 232.836L268.255 256.687C268.255 256.687 273.471 259.918 277.48 263.846Z" fill="#E6685A"/>
            </g>
        </g>
        <path d="M287.307 282.266C287.071 282.289 286.869 282.342 286.706 282.425L287.912 283.871C287.912 283.871 287.661 283.212 287.307 282.266Z" fill="#423E3E"/>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M276.19 261.881L256.044 231.2C256.044 231.2 254.925 230.369 252.884 232.305C253.598 231.882 255.014 231.145 255.645 231.527C256.504 232.049 276.078 261.872 276.078 261.872L276.19 261.881Z" fill="#E6685A"/>
            </g>
        </g>
        <g opacity="0.5">
            <g opacity="0.5">
                <path opacity="0.5" d="M387.95 191.417L386.626 191.456C386.626 191.456 377.3 190.966 376.317 200.416C375.336 209.865 365.64 290.008 365.64 290.008C365.64 290.008 364.781 294.425 372.513 293.444C378.254 292.714 427.359 285.356 452.27 281.614C384.614 283.76 386.094 212.22 387.95 191.417Z" fill="#F2F7FD"/>
            </g>
        </g>
        <path d="M291.921 284.403C291.921 284.403 288.976 285.426 281.653 285.14C274.329 284.854 265.493 285.999 263.04 286.285" stroke="#9A9391" strokeWidth="0.185333" strokeMiterlimit="10" strokeLinecap="round"/>
        <path d="M379.344 9.16342H31.1222V6.54404e-06H379.344V9.16342Z" fill="#677786"/>
        <path d="M379.345 1.00017H379.339H378.48H31.1228V1.79207H378.48V10.1636H379.339V1.79207H379.345V1.00017Z" fill="#677786"/>
        <path d="M31.1231 13.1637H31.1284H31.9875H379.345V12.3718H31.9875V4.00032H31.1284V12.3718H31.1231V13.1637Z" fill="#677786"/>
    </svg>
